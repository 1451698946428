<template>
    <div>
        <card-header title="Edit Event" icon="fa-pencil"/>

        <card-body>
            <event-form :event="event"/>
        </card-body>

        <card-footer class="pt-3 pb-3">
            <div class="columns">
                <div class="column">
                    <b-button type="is-warning is-outlined" size="is-small" @click="deleteEvent"><i class="far fa-trash-alt"></i><span class="ml-2" v-if="confirmDelete">Delete Event?</span></b-button>
                </div>
                <div class="column has-text-right">
                    <b-button type="is-primary" size="is-small" :disabled="!event.title || !event.location || !event.start_date || !event.start_time || !event.start_tz || !event.end_date || !event.end_time || !event.end_tz" @click="submit"><i class="fas fa-check mr-2"></i>Save Event</b-button>
                </div>
            </div>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardBody from "../../TIER/components/CardBody";
    import CardFooter from "../../TIER/components/CardFooter";
    import EventForm from "./EventForm";
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";
    import async from "async";
    import {client as http} from "../../http_client";

    export default {
        components: {ContainerListItem, SubheaderListItem, CardList, EventForm, CardFooter, CardBody, CardHeader},
        props: ['card', 'props'],
        data: function() {
            return {
                event: {},
                confirmDelete: false,
                dirty: null
            };
        },
        methods: {
            loadEvent: function() {
                this.$emit('loading', true);
                this.dirty = null;

                async.series([
                    // Get event
                    (cb) => {
                        http.get('/api/trips/' + this.props.tripId + '/days/' + this.props.dayId + '/events/' + this.props.eventId, {force: true}).then(response => {
                            this.event = response.data;
                            cb();
                        });
                    }
                ], err => {
                    this.$emit('loading', false);
                });
            },
            submit() {
                this.$emit('loading', true);
                http.put('/api/trips/' + this.props.tripId + '/days/' + this.props.dayId + '/events/' + this.props.eventId, this.event, {force: true}).then(response => {
                    this.$reloadCard('tasks');
                    this.$reloadCard('day', {dayId: this.props.dayId});
                    this.$reloadCard('days', {tripId: this.props.tripId});
                    this.$reloadCard('trip', {tripId: this.props.tripId});
                    this.$reloadCard('trips');
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            },
            deleteEvent() {
                if (this.confirmDelete) {
                    this.$emit('loading', true);

                    http.delete('/api/trips/' + this.props.tripId + '/days/' + this.props.dayId + '/events/' + this.props.eventId).then(response => {
                        this.$reloadCard('tasks');
                        this.$reloadCard('days', {tripId: this.props.tripId});
                        this.$saveAlert(false);
                        this.$closeCard(this.card);
                    }).catch(err => {

                    });
                } else {
                    this.confirmDelete = true;
                    setTimeout(() => {
                        this.confirmDelete = false;
                    }, 3000);
                }
            }
        },
        watch: {
            'props.eventId': function() {
                this.dirty = null;
                this.loadEvent();
            },
            'event': {
                deep: true,
                handler: function() {
                    this.dirty = (this.dirty !== null);
                    if (!this.$TIER.saveAlert.active && this.dirty)
                        this.$saveAlert(true, 'Are you done editing this event? Unsaved changes will be lost.');
                }
            }
        },
        mounted() {
            this.loadEvent();
        }
    };
</script>

<style scoped>

</style>
